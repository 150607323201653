import React, { useEffect, useState, useRef } from "react"
import { Link, navigate, graphql } from "gatsby"
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from "../../lib/helpers"
import Container from "../../components/Container/Container"
import GraphQLErrorList from "../../components/graphql-error-list"
import Seo from "../../components/SEO/SEO"
import Image from "gatsby-plugin-sanity-image"
import InView from "../../components/InView"
import { useMediaQuery } from "react-responsive"
import useParallaxBlock from "../../lib/parallaxBlock"
import Enquiry from "../../components/Global/Enquiry"
import ProjectPreview from "../../components/Projects/ProjectPreview"

// import Swiper core and required modules
import { A11y } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/css"
import "swiper/css/effect-fade"

export const query = graphql`
  query ResidentialProjectPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      defaultImage {
        asset {
          _id
        }
      }
      enquiryCTAImage {
        ...Image
      }
      enquiryCTATitle
      enquiryCTAText
    }
    sanityResidentialProjects(_id: { regex: "/^(?!drafts.).*$/" }) {
      title
      description
      _id
      hero {
        kind
        heroImage {
          ...Image
          alt
        }
        heroVideoUrl
      }
      moreProjects
      moreProjectsTitle
      moreProjectsLinkText
    }
    sanityPublicRealmProjects(_id: { regex: "/^(?!drafts.).*$/" }) {
      title
      description
      _id
      hero {
        heroImage {
          ...Image
          alt
        }
      }
    }
    sanityRetailProjects(_id: { regex: "/^(?!drafts.).*$/" }) {
      title
      description
      _id
      hero {
        heroImage {
          ...Image
          alt
        }
      }
    }
    projects: allSanityProject(
      sort: { fields: [order], order: ASC }
      filter: { published: { ne: false } }
    ) {
      edges {
        node {
          id
          previewImage {
            ...Image
            alt
          }
          title
          projectStatus
          slug {
            current
          }
          projectType {
            id
            title
          }
        }
      }
    }
    categories: allSanityProjectType(
      sort: { fields: [sort_order], order: ASC }
    ) {
      edges {
        node {
          title
          id
        }
      }
    }
  }
`

const ResidentialProjects = (props) => {
  const { data, errors } = props
  const site = (data || {}).site
  const [projects, setProjects] = useState([])
  const [loaded, setLoaded] = useState(false)
  const [hideProjects, setHideProjects] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState(null)
  const [selectedOption, setSelectedOption] = useState("/projects")
  const topRef = useRef()

  const projectNodes = (data || {}).projects
    ? mapEdgesToNodes(data.projects).filter(filterOutDocsWithoutSlugs)
    : []

  useEffect(() => {
    filterProjects("-3b53f52f-2624-5cf7-a8e7-efa21e8fb748")
  }, [data.categories])

  useEffect(() => {
    setProjects(projectNodes)
  }, [])

  function filterProjects(filter) {
    setSelectedFilter(filter)
    setHideProjects(true)
    setTimeout(() => {
      if (filter) {
        setProjects(
          projectNodes.filter((el) =>
            el.projectType.map((el) => el.id).includes(filter)
          )
        )
      } else {
        setProjects(projectNodes)
      }
      setHideProjects(false)
    }, 600)
  }

  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1024 })
    return isDesktop ? children : null
  }
  const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ maxWidth: 1023 })
    return isNotMobile ? children : null
  }

  const parallaxA = useParallaxBlock((scale) => {
    return 0.6 + scale * 0.6
  })

  const parallaxB = useParallaxBlock((scale) => {
    return 1.2 - scale * 1.2
  })

  function ProjectListing(props) {
    return (
      <>
        <Default>
          <div className="col-1">
            {props.nodes &&
              props.nodes
                .filter((n, i) => i % 2 === 0)
                .map((node) => (
                  <div className="mb-6 md:mb-10 md:pl-10" key={node.id}>
                    <ProjectPreview {...node} />
                  </div>
                ))}
          </div>
          <div className="col-2">
            {props.nodes &&
              props.nodes
                .filter((n, i) => i % 2 === 1)
                .map((node) => (
                  <div className="mb-6 md:mb-10 md:pl-10" key={node.id}>
                    <ProjectPreview {...node} />
                  </div>
                ))}
          </div>
        </Default>
        <Desktop>
          <div className="col-1" ref={parallaxB}>
            {props.nodes &&
              props.nodes
                .filter((n, i) => i % 3 === 0)
                .map((node) => (
                  <div
                    className="overflow-hidden mb-6 md:mb-10 md:pl-10"
                    key={node.id}
                  >
                    <ProjectPreview {...node} />
                  </div>
                ))}
          </div>
          <div className="col-2">
            {props.nodes &&
              props.nodes
                .filter((n, i) => i % 3 === 1)
                .map((node) => (
                  <div className="mb-6 md:mb-10 md:pl-10" key={node.id}>
                    <ProjectPreview {...node} />
                  </div>
                ))}
          </div>
          <div className="col-3" ref={parallaxA}>
            {props.nodes &&
              props.nodes
                .filter((n, i) => i % 3 === 2)
                .map((node) => (
                  <div className="mb-6 md:mb-10 md:pl-10" key={node.id}>
                    <ProjectPreview {...node} />
                  </div>
                ))}
          </div>
        </Desktop>
      </>
    )
  }

  ProjectListing.defaultProps = {
    title: "",
    nodes: [],
  }

  const handleChange = (event) => {
    const newPath = event.target.value
    setSelectedOption(newPath)
    navigate(newPath)
  }

  if (errors) {
    return (
      <>
        <GraphQLErrorList errors={errors} />
      </>
    )
  }

  return (
    <>
      <Seo
        title={data.sanityResidentialProjects.title}
        description={data.sanityResidentialProjects.description}
        image={
          data.sanityResidentialProjects.hero.heroImage
            ? data.sanityResidentialProjects.hero.heroImage
            : site.defaultImage
        }
      />
      <Container>
        <div className="box-border border-b border-grey-default">
          <div className="grid grid-cols-1 mb-4 md:grid-cols-2 bg-off-white md:mb-0">
            <div className="px-6 py-10 md:pl-10 md:my-[120px] md:mr-[120px] flex flex-col order-2 md:order-1">
              <div className="md:my-auto text-[14px] leading-[24px] max-w-[400px]">
                <h2>Our Projects</h2>
                <h1 className="font-serif text-[40px] leading-[1.4] md:text-[56px] md:leading-[72px] my-6 md:my-10">
                  {data.sanityResidentialProjects.title}
                </h1>
                <div className="text-[#666]">
                  {data.sanityResidentialProjects.description}
                </div>
                <Link
                  className="link text-[#1B1B1B] mt-6 md:mt-10"
                  to="/contact-us/"
                >
                  Enquire now
                </Link>
              </div>
            </div>
            {data.sanityResidentialProjects.hero.kind === "image" && (
              <div className="order-1 md:order-2">
                <Image
                  {...data.sanityResidentialProjects.hero.heroImage}
                  width={880}
                  height={880}
                  alt={data.sanityResidentialProjects.hero.heroImage.alt}
                  className={
                    `aspect-[390/305] md:aspect-[720/610] object-cover object-center h-full lazy ` +
                    (loaded && " loaded")
                  }
                  onLoad={() => setLoaded(true)}
                />
              </div>
            )}
          </div>

          <InView>
            <div className="relative pb-10 md:pb-0">
              <div className="container relative flex flex-wrap flex-start md:pt-[120px] md:h-full md:min-h-screen">
                <div className="w-full pt-6 md:pt-0 md:w-1/4 md:sticky md:h-full md:top-[81px] md:pb-[25px] md:pr-10">
                  <h3 className="text-[16px] leading-[28px] mb-6">
                    Filter by type
                  </h3>
                  <ul className="font-serif text-[24px] lg:text-[32px] leading-[1.2] hidden md:flex md:flex-col md:gap-4">
                    <li>
                      <Link
                        to="/projects"
                        className="border-b transition-all duration-300 ease-in-out border-b-transparent text-grey-default hover:text-black hover:border-b hover:border-black"
                      >
                        All
                      </Link>
                    </li>
                    <li className="my-px">
                      <Link
                        to="/projects/residential"
                        className="border-b border-b-black"
                      >
                        Residential
                      </Link>
                    </li>
                    <li className="my-px">
                      <Link
                        to="/projects/retail"
                        className="border-b transition-all duration-300 ease-in-out border-b-transparent text-grey-default hover:text-black hover:border-b hover:border-black"
                      >
                        Retail
                      </Link>
                    </li>
                    <li className="my-px">
                      <Link
                        to="/projects/public-realm"
                        className="border-b transition-all duration-300 ease-in-out border-b-transparent text-grey-default hover:text-black hover:border-b hover:border-black"
                      >
                        Public Realm
                      </Link>
                    </li>
                  </ul>
                  <select
                    className="md:hidden text-[14px] text-[#2e2e2e] text-opacity-50 w-full border p-3 border-grey-default box-border"
                    value={selectedOption}
                    onChange={handleChange}
                  >
                    <option value="/projects">All</option>
                    <option value="/projects/residential">Residential</option>
                    <option value="/projects/retail">Retail</option>
                    <option value="/projects/public-realm">Public Realm</option>
                  </select>
                </div>
                <div className="mt-6 masonry--col-3 md:mt-0 w-full md:w-3/4 md:pb-[30%] relative md:border-l md:border-grey-default box-border">
                  <div
                    ref={topRef}
                    className="absolute top-[-100px] left-0"
                  ></div>
                  <div className="grid gap-6 masonry md:grid-cols-2 lg:grid-cols-3 md:gap-10">
                    <ProjectListing nodes={projects} />
                  </div>
                </div>
              </div>
            </div>
          </InView>
        </div>
        <Enquiry data={data} />
        <InView>
          <div className="container related-projects">
            <div className="py-6 md:py-10">
              <h2>
                {data.sanityResidentialProjects.moreProjectsTitle
                  ? data.sanityResidentialProjects.moreProjectsTitle
                  : "More projects"}
              </h2>
              {data.sanityResidentialProjects.moreProjects ? (
                <h3 className="text-[32px] leading-[1.2] font-serif my-6 md:mt-[120px] md:mb-10 max-w-[680px]">
                  {data.sanityResidentialProjects.moreProjects}
                </h3>
              ) : (
                <h3 className="text-[32px] leading-[1.2] font-serif my-6 md:mt-[120px] md:mb-10 max-w-[680px]">
                  Creating places that blur the lines between lifestyle, urban
                  living and design
                </h3>
              )}
              <Link className="mt-2 text-sm link md:mt-auto" to="/projects">
                {data.sanityResidentialProjects.moreProjectsLinkText
                  ? data.sanityResidentialProjects.moreProjectsLinkText
                  : "View all projects"}
              </Link>
            </div>
          </div>
          <div className="mx-auto w-full max-w-full recent-projects">
            <Swiper
              modules={[A11y]}
              slidesPerView={1.3}
              speed={1000}
              breakpoints={{
                1024: {
                  slidesPerView: 2,
                },
              }}
            >
              <SwiperSlide>
                <div className="pb-6 lg:pb-10">
                  <div className="overflow-hidden">
                    <Link to="/projects/public-realm">
                      <Image
                        {...data.sanityPublicRealmProjects.hero.heroImage}
                        width={930}
                        height={740}
                        alt={data.sanityPublicRealmProjects.hero.heroImage.alt}
                        className={
                          `transition-transform duration-500 ease-in-out md:hover:transform md:hover:scale-[1.05] lazy ` +
                          (loaded && " loaded")
                        }
                        onLoad={() => setLoaded(true)}
                      />
                    </Link>
                  </div>
                  <h4 className="font-serif text-[32px] leading-[1.2] mt-10 mb-4">
                    Public Realm
                  </h4>
                  <Link className="text-sm link" to="/projects/public-realm">
                    View project
                  </Link>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="pb-6 lg:pb-10">
                  <div className="overflow-hidden">
                    <Link to="/projects/retail">
                      <Image
                        {...data.sanityRetailProjects.hero.heroImage}
                        width={930}
                        height={740}
                        alt={data.sanityRetailProjects.hero.heroImage.alt}
                        className={
                          `transition-transform duration-500 ease-in-out md:hover:transform md:hover:scale-[1.05] lazy ` +
                          (loaded && " loaded")
                        }
                        onLoad={() => setLoaded(true)}
                      />
                    </Link>
                  </div>
                  <h4 className="font-serif text-[32px] leading-[1.2] mt-10 mb-4">
                    Retail
                  </h4>
                  <Link className="text-sm link" to="/projects/retail">
                    View project
                  </Link>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </InView>
      </Container>
    </>
  )
}

export default ResidentialProjects
